/* You can add global styles to this file, and also import other style files */
@import "icons";
@import "breakpoints";
@import "colors";

.nsf-sidebar-separator {
  border-top: .03125rem solid $grey3;
  padding-bottom: .75rem;
}

// #region ACCORDION
.nsf-custom-accordion {
  .accordion-item {
    border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color) !important;
  }
}
// #endregion ACCORDION

// #region TIMESHEET
.nsf-timesheet-tooltip-order-event {
  .tooltip-arrow::before {
    border-top-color: $primary !important;
  }

  .tooltip-inner {
    border: 0.1rem solid $primary;
    background-color: $primary-light;
    color: $primary-dark;
  }
}

.nsf-timesheet-tooltip-overtime-event {
  .tooltip-arrow::before {
    border-top-color: $secondary !important;
  }

  .tooltip-inner {
    border: 0.1rem solid $secondary;
    background-color: $secondary-light;
    color: $secondary-dark;
  }
}

.nsf-timesheet-tooltip-absence-event, .nsf-timesheet-tooltip-funded-activity-event {
  .tooltip-arrow::before {
    border-top-color: $danger !important;
  }

  .tooltip-inner {
    border: 0.1rem solid $danger;
    background-color: $danger-light;
    color: $danger-dark;
  }
}

.nsf-timesheet-tooltip-funded-order-event {
  .tooltip-arrow::before {
    border-top-color: $success !important;
  }

  .tooltip-inner {
    border: 0.1rem solid $success;
    background-color: $success-light;
    color: $success-dark;
  }
}

.nsf-timesheet-header-cell {
  border: 1px solid $grey3 !important;
  background-color: $grey1;

  a {
    color: $black;
  }

  &:hover {
    box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.04);
  }
}

.nsf-timesheet-day-cell {
  border: 1px solid $grey3 !important;

  &:hover {
    box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.04);
  }

  &.fc-daygrid-day.fc-day-today {
    //background-color: rgba(236, 0, 140, 0.10);
    background-color: rgba(108, 117, 125, 0.1);
  }

  &.fc-daygrid-day.fc-day-sat,
  &.fc-daygrid-day.fc-day-sun {
    background-color: rgba(220, 53, 69, 0.1);
  }

  div.fc-daygrid-event-harness {
    margin-bottom: 0.25rem;
  }

  a {
    color: $secondary !important;
    font-weight: 700;
  }
}

.nsf-timesheet-event {
  &.nsf-timesheet-holiday-event {
    background-color: rgba(220, 53, 69, 0.1) !important;
    opacity: unset;

    div.fc-event-title {
      font-weight: 700;
    }
  }

  &.nsf-timesheet-unavailable-event {
    background-color: rgba(255, 193, 7, 0.1) !important;
    opacity: unset;

    div.fc-event-title {
      font-weight: 700;
    }
  }

  &.nsf-timesheet-order-event {
    background-color: $primary-light;
    border-color: $primary-light;
    //border-color: $primary;

    div.fc-event-main {
      color: $primary-dark;
    }

    &:hover {
      background-color: $primary;

      div.fc-event-main {
        color: $white;
      }
    }
  }

  &.nsf-timesheet-overtime-event {
    background-color: $secondary-light;
    border-color: $secondary-light;
    //border-color: $secondary;

    div.fc-event-main {
      color: $secondary-dark;
    }

    &:hover {
      background-color: $secondary !important;

      div.fc-event-main {
        color: $white !important;
      }
    }
  }

  &.nsf-timesheet-absence-event, &.nsf-timesheet-non-working-event, &.nsf-timesheet-funded-activity-event {
    background-color: $danger-light;
    border-color: $danger-light;
    //border-color: $danger;

    div.fc-event-main {
      color: $danger-dark;
    }

    &:hover {
      background-color: $danger;

      div.fc-event-main {
        color: $white;
      }
    }
  }

  &.nsf-timesheet-funded-order-event {
    background-color: $success-light;
    border-color: $success-light;
    //border-color: $primary;

    div.fc-event-main {
      color: $success-dark;
    }

    &:hover {
      background-color: $success;

      div.fc-event-main {
        color: $white;
      }
    }
  }

  &.nsf-timesheet-remaining-hours {
    background-color: $white;

    div.fc-event-title {
      color: black;
      font-size: .75rem;
      font-weight: bold;
    }
  }

  div.fc-event-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
// #endregion TIMESHEET
