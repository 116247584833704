$white: #ffffff;
$dkGray: #303030;
$ltGray: #cecece;

// #region BASIC COLORS
$primary: #EC008C;
$black: #212529;
$grey: #727272;
$grey1: #efefef;
$grey2: #f9f9f9; // bg color
$grey3: #d8d8d8; // bg avatar
$grey4: #a4a4a4; // placeholder grey
$white1: #fafafa;
$primary-hover: #d2007c;
$info-hover: #1597ab;
$autofill: #ffeff9;
$violet: #cf4dcf;
// #endregion BASIC COLORS

// #region BACKGROUND COLORS
$primary-bg: #fff7fe;
$primary-dark-bg: #ffe8fc;
// #endregion BACKGROUND COLORS

// #region BOX-SHADOW COLORS
$shadow-primary: 0 0 0 0.25rem rgb(236 0 140 / 25%);
$shadow-black: 0 .5rem 1rem rgba(0, 0, 0, .15);
$border-primary: #f682c6;
// #endregion BOX-SHADOW COLORS

// #region BOOTSTRAP COLORS
$secondary: #6c757d;
$secondary-light: #e1e3e5;
$secondary-dark: #4c5257;
$success: #198754;
$success-light: #c4f4de;
$success-dark: #115e3b;
$danger: #dc3545;
$danger-light: #f8d7da;
$danger-dark: #a31c29;
$warning: #ffc107;
$warning-light: #fff3cd;
$warning-dark: #836200;
$info: #17a2b8;
$info-light: #c9f2f8;
$info-dark: #0e616e;
$black-light: #cfd3d8;
$black-dark: #171a1d;
$primary-light: #ffc8e9;
$primary-dark: #a50062;
$light: #f8f9fa;
$dark: #343a40;
$white: #fff;
// #endregion BOOTSTRAP COLORS
